import React from 'react'
import styled from 'styled-components'

import Layout from '../components/Layout/layout'
import SEO from '../components/SEO/seo'
import Break from '../images/break.svg'

import dogs from './dogs.png'
import events from './events.png'
import atts from './atts.png'
import medal from './medal.svg'

const HomeLayout = styled.main`
  max-width: 90%;
  padding-top: 1rem;
  text-align: center;
  margin: 0 auto;
  display: grid;
  grid-template-columns: auto;
  grid-gap: 10px;
  justify-items: center;
`
const StyledTitle = styled.h1`
  ${props => props.theme.defaults.primaryTextUpper};
  font-size: 50px;
`
const StyledInstructions = styled.h2`
  ${props => props.theme.defaults.secondaryTextUpper};
  font-size: 40px
  line-height: 96%;
`
const ImageHolder = styled.img`
  width: 90%;
`
const SmallMedal = styled.img`
  width: 40%;
`

const HomePage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <HomeLayout>
      <StyledTitle>Draft Your Dogs</StyledTitle>
      <StyledInstructions>Will you favor high endurance Huskies, or strong Shepherds?</StyledInstructions>

      <ImageHolder src={dogs} alt="Three cards with dogs on them"/>
      <Break />
      <StyledTitle>Train Your Team</StyledTitle>
      <StyledInstructions>There are 4 positions, each requiring the right pup with a unique skillset</StyledInstructions>
      <img src={atts} alt="Five cards with attributes on them"></img>
      <Break />
      <StyledTitle>Race For Glory!</StyledTitle>
      <StyledInstructions>Take risks, and use your resources wisely. </StyledInstructions>
      <ImageHolder src={events} alt="Four cards with events on them"/>
      <StyledInstructions>Try to maintain a lead while keeping your dogs safe!</StyledInstructions>
      <SmallMedal src={medal} alt="Four cards with events on them"/>
    </HomeLayout>
  </Layout>
)

export default HomePage
